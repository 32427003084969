@charset "utf-8"

@import "../../styles/bulma_legacy/functions.sass"
@import "../../styles/bulma_legacy/mixins.sass"
@import "../../styles/variables.sass"
































































































































.navigation-segment
  z-index: $z-index-menu + 1

  .container

    .navigation

      li
        border-top: 1px solid $grey-lighter
        font-size: $size-3
        width: 100%

        &:first-child
          border-top: none

        a
          display: inline-block
          padding: 1rem 0
          width: 100%

          &.router-link-exact-active
            color: $grey-dark
            cursor: default

          .icon
            float: right

            .svg-icon
              width: 1.2rem
              height: 1.2rem

        > ul

          li
            padding-left: 1.5rem

            &:first-child
              border-top: 1px solid $grey-lighter

+tablet

  .navigation-segment
    position: absolute
    top: 0
    right: 0
    left: 0
    height: 0
    width: 100%
    background-color: transparent
    z-index: $z-index-header + 1

    .container
      position: relative

      .navigation
        position: absolute
        top: .8rem
        right: $content-padding-desktop

        li
          float: left
          width: auto
          font-size: $size-6
          border: none

          a
            position: relative
            float: left
            display: inline-block
            color: $text-invert
            padding: .35rem .7rem

            &.router-link-exact-active, &:hover
              color: $text-invert

              &::after
                content: ""
                position: absolute
                border-top: 1px solid
                left: .7rem
                right: .7rem
                bottom: .375rem

          &:last-child
            margin-right: 0

            .button
              padding-right: 0

          &.calculator
            display: none

+desktop

  .navigation-segment

    .container

      .navigation
        top: 1rem

