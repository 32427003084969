@charset "utf-8"

@import "../../styles/bulma_legacy/functions.sass"
@import "../../styles/bulma_legacy/mixins.sass"
@import "../../styles/variables.sass"

















































































































































































































































































































































































































































































































































.slider-input

  .fieldlabel
    position: relative
    min-height: $icon-small

    .lock
      position: absolute
      top: .2rem
      right: 0

  .control
    position: relative

    .input
      height: 3.2rem
      color: $info
      text-align: center
      font-size: $size-1
      font-family: $family-mono-light

      &.readonly
        background-color: $white-ter

        &:hover,
        &:focus,
        &:active
          border-color: $border

      &.error
        color: $red

    .step
      display: none

  .slider
    position: relative
    height: 3rem
    margin-top: $content-padding-mobile
    padding-top: 1.25rem

    .slider-frame
      position: absolute
      top: 7px
      left: 7px
      right: 7px
      height: 14px
      padding: 2px
      background-color: $white
      border: 1px solid $border

      .slider-bar
        width: 100%
        height: 100%
        background-color: $grey-lighter

        .vue-slider-component
          position: relative
          padding: 0px !important

          .vue-slider
            background-color: $white
            border-radius: 0px

          .vue-slider-process
            background-color: $primary
            border-radius: 0px

          .vue-slider-dot

            &:after
              position: absolute
              content: ""
              top: 2px
              left: 2px
              width: 16px
              height: 16px
              background-color: $primary
              border-radius: 8px

    .slider-measure
      padding: 0 5px
      margin: .4rem .25rem
      height: .3rem

      .tick
        position: relative
        display: block
        width: 5%
        height: .4em
        float: left

        &:after,
        &:before
          position: absolute
          content: ""
          display: block
          width: 1px
          top: .1rem
          height: 50%
          background: #5a585b
          background-image: linear-gradient(#020206, #737476)

        &:after
          margin-left: 50%

        &:first-child
          &:before
            top: 0
            height: 100%

        &:last-child
          margin-right: -1px
          width: 0

          &:after
            top: 0
            height: 100%

    .slider-boundaries
      font-size: .65rem
      font-family: $family-mono-light

      .boundary
        color: $grey

        &.max
          float: right

+tablet
  .slider-input

    .slider
      height: 4rem

+desktop

  .slider-input

    .control

      .step
        display: inline-block
        position: absolute
        right: .85rem

        &.step-up
          top: .9rem

        &.step-down
          top: 1.6rem

