@charset "utf-8"

@import "../../styles/bulma_legacy/functions.sass"
@import "../../styles/bulma_legacy/mixins.sass"
@import "../../styles/variables.sass"








































































































.select-input

  .control-frame
    padding: 2px
    background-color: $white
    border: 1px solid $border

    .control
      position: relative
      display: flex
      background-color: $white
      height: 2rem

      .select-label
        padding: 0 1.1rem 0 .3rem
        line-height: 2rem
        font-size: $size-4
        color: $info
        font-family: $family-mono-light
        cursor: pointer
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

        &.error
          color: $red
          font-family: $family-mono

      .tke_icon_select
        position: absolute
        width: 1.1rem
        height: 1.1rem
        right: .3rem
        top: .5rem
        fill: $primary

      select
        position: absolute
        width: 100%
        padding: 0
        height: 1.5rem
        opacity: 0
        filter: alpha(opacity=0)
        visibility: visible
        font-size: .8rem
        border: 0 // Safari

+tablet

  .select-input

    .control-frame

      .control
        height: 1.5rem

        .select-label
          line-height: 1.5rem
          font-size: $size-6

        .tke_icon_select
          top: .2rem

